import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useThrottleFn } from "ahooks"

import Navigation from "./navigation"
import Logotype from "../logotype/logotype"
import Buttons from "../buttons/buttons"

import * as styles from "./header.module.scss"

const MAX_HEIGHT = 123

const Subheader = props => {
  const [height, setHeight] = useState(MAX_HEIGHT)
  const { links, buttons, headerLinks, theme } = props

  const { run: scrollListener } = useThrottleFn(
    () => {
      if (height === 50 && window.scrollY > 73) return
      const newHeight = Math.max(MAX_HEIGHT - window.scrollY, 50)
      const topPopup = document.getElementById("top-popup")
      if (topPopup && topPopup.style.top !== "-20px") {
        topPopup.style.top = `${50 + newHeight}px`
      }
      setHeight(newHeight)
    },
    { wait: 100 }
  )

  useEffect(() => {
    document.addEventListener("scroll", scrollListener)
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [scrollListener])

  return (
    <div className={classNames(styles.subheader, height === 50 && styles.shadow)} style={{ height }}>
      <Logotype className={styles.logotype} theme={theme} />
      <Navigation isSubheader links={headerLinks} />
      <Navigation isSubheader links={links} />
      <Buttons className={styles.buttonContainer} buttons={buttons} />
    </div>
  )
}

export default Subheader
