import React from "react"
import cx from "classnames"
import { useLocation } from "@reach/router"

import GetInTouch from "./getInTouch"
import Categories from "./categories"
import FormattedLink from "../formattedLink/formattedLink"
import LtgLogo from "../../../assets/images/ltg-logo.png"
import { useQuery } from "../../graphql/useQuery"
import { markets, getSanityMarketId } from "../../consts/markets"

import * as styles from "./footer.module.scss"

const getFooterId = location => {
  const [, market] = location.pathname.split("/")
  if (!Object.values(markets).includes(market)) return "footer-en-us"
  const marketID = getSanityMarketId(market)
  return `footer-${marketID}`
}

const Footer = () => {
  const location = useLocation()
  const footerId = getFooterId(location)
  const { data } = useQuery(`*[_type == "footer" && _id == "${footerId}"][0]`)

  const { categories, getInTouch } = data || {}

  const Legal = () => (
    <div className={styles.legalWrapper}>
      <ul>
        {(getInTouch?.legal || []).map(link => (
          <li key={link._key}>
            <FormattedLink url={link.url} label={link.label} newTab={link.newTab} />
          </li>
        ))}
      </ul>
      <img className={styles.ltgLogo} src={LtgLogo} alt="ltg logo" />
    </div>
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.categoryPart}>
        <Categories
          wrapperStyles={styles.categoryWrapper}
          data={categories || []}
          categoryStyles={styles.category}
          titleStyles={cx(styles.title, styles.categoryTitle)}
        />
      </div>
      <div className={styles.getInTouchPart}>
        <GetInTouch data={getInTouch || {}} />
      </div>
      <div className={styles.legalPart}>
        <Legal />
      </div>
    </footer>
  )
}

export default Footer
