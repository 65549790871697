import React from "react"
import RichText from "../richText/richText"

import * as styles from "./table.module.scss"

const MobileTable = props => {
  const { heading, rows } = props

  const renderItem = (rowItem, rowIndex) => {
    const row = rowItem.flat().filter(cell => typeof cell !== "string")
    return (
      <div className={styles.item} key={rowIndex}>
        <div className="mb-xs body1">{`${rowIndex + 1}.`}</div>
        {row.map((cell, idx) => (
          <div className={styles.itemRow} key={idx}>
            <strong>
              <RichText blocks={heading[idx].cell} />:
            </strong>
            <span className="ml-xs">
              <RichText blocks={cell.cell} />
            </span>
          </div>
        ))}
      </div>
    )
  }

  return <div className={styles.mobileTable}>{rows.map(renderItem)}</div>
}

export default MobileTable
