import { useLocation } from "@reach/router"
import { markets } from "../consts/markets"

/**
 * @returns {string}
 * @description returns homepage link (depends on the market we are in)
 */
export const useHomepageLocation = () => {
  const location = useLocation()
  const [, market] = location.pathname.split("/")
  const homepageLink = Object.values(markets).includes(market) && market !== markets.US ? `/${market}` : "/"
  return homepageLink
}
