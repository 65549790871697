import { useCallback, useEffect } from "react"
import { useLocation } from "@reach/router"

import AU from "../locales/au"
import ES from "../locales/es"
import EN from "../locales/en"
import PT from "../locales/pt"

/**
 * @returns {void}
 * @descriptions Checks if the dictionaries have the same keys. Logs an error if it does not.
 */
const validateDictionaries = () => {
  for (let key in EN) {
    if (EN.hasOwnProperty(key)) {
      if (!ES.hasOwnProperty(key)) console.error(`[i18n]: Dictionaries has different key - ${key}`)
      if (!AU.hasOwnProperty(key)) console.error(`[i18n]: Dictionaries has different key - ${key}`)
      if (!PT.hasOwnProperty(key)) console.error(`[i18n]: Dictionaries has different key - ${key}`)
    }
  }
}

/**
 *
 * @param {string} market
 * @returns {object}
 */
const getMarketDictionary = (market) => {
  switch (market) {
    case "es": return ES;
    case "au": return AU;
    case "pt": return PT;
    case "en":
      default: return EN;
  }
}

/**
 * @returns {Function}
 */
export const useTranslation = () => {
  const { pathname } = useLocation()
  const market = pathname.split("/")[1];

  useEffect(() => {
    validateDictionaries()
  }, [])

  /**
   *
   * @param {string} key
   * @returns {string | React.Element}
   * @description Returns translation from the current dictionary. Returns provided key if nothing's found
   */
  const getTranslation = useCallback((key) => {
    const currentDic = getMarketDictionary(market);
    const translation = currentDic[key];

    if (!translation) {
      console.error(`[i18n]: No translations found for the key ${key}`);
      return key;
    }
    return translation;
  }, [market])

  return { t: getTranslation };
}