import { window } from "browser-monads"

/**
 * @returns {string}
 * @description returns pagePath
 */

export const getPagePath = () => {
  return window.location.href
}

/**
 * @returns {string}
 * @description returns hreflang abbreviation
 */

export const getHreflang = () => {
  const pagePath = getPagePath()
  if (pagePath.includes("/es")) return "es"
  if (pagePath.includes("/au")) return "en-au"
  if (pagePath.includes("/pt")) return "pt"
  return "en"
}
