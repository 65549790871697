import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { IoClose } from "react-icons/io5"

import * as styles from "./modal.module.scss"

/**
 *
 * @description
 * Auto mode:
 * - let you not to use unnecessary states
 * - Assigns handleOpen to children
 */
function Modal(props) {
  const [open, setOpen] = useState(false)
  const {
    children,
    open: outerOpen,
    onClose,
    isAuto,
    modalContent,
    className,
    modalClassName,
    width,
    height,
    canScroll,
    isPopup,
    handleClickForPopup,
  } = props

  const watchEscape = useCallback(
    e => {
      if (e.which === 27) setOpen(false)
      isPopup && handleClickForPopup()
    },
    [handleClickForPopup, isPopup]
  )

  useEffect(() => {
    if (!open) return
    document.addEventListener("keyup", watchEscape)
    return () => {
      document.removeEventListener("keyup", watchEscape)
    }
  }, [open, watchEscape])

  /** disables global html scroll */
  useEffect(() => {
    if (canScroll) {
      return null
    } else {
      const html = document.querySelector("html")
      html.style.overflowY = open ? "hidden" : "scroll"
    }
  }, [open, canScroll])

  useEffect(() => {
    if (!onClose) return
    if (!open) onClose()
  }, [open, onClose])

  useEffect(() => {
    setOpen(!!outerOpen)
  }, [outerOpen])

  const handleClose = useCallback(
    e => {
      e.stopPropagation()
      isPopup && handleClickForPopup()
      setOpen(false)
    },
    [handleClickForPopup, isPopup]
  )

  const handleOpen = useCallback(e => {
    e.stopPropagation()
    setOpen(true)
  }, [])

  const size = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  }

  const renderModal = () =>
    open ? (
      <div
        className={classNames(styles.modal)}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        data-popup={isPopup}
        tabIndex={0}>
        <div className={styles.closeButton} onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
          <span>close</span>
          <IoClose size={30} />
        </div>
        <div className={classNames(modalClassName, styles.content)} style={{ ...size }}>
          {modalContent}
        </div>
      </div>
    ) : null

  if (isAuto) {
    return (
      <div
        className={classNames(styles.autoModal, className)}
        onClick={handleOpen}
        onKeyDown={handleOpen}
        role="button"
        tabIndex={0}>
        {children}
        {renderModal()}
      </div>
    )
  }

  return renderModal()
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isAuto: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalContent: PropTypes.element.isRequired,
  canScroll: PropTypes.bool,
  isPopup: PropTypes.bool,
  handleClickForPopup: PropTypes.func,
}

Modal.defaultProps = {
  open: false,
  onClose: null,
  isAuto: false,
  width: "",
  height: "",
  canScroll: false,
  isPopup: false,
  handleClickForPopup: null,
}

export default Modal
