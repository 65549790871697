// extracted by mini-css-extract-plugin
export var sbShowMain = "header-module--sb-show-main--1DP0Q";
export var headerWrapper = "header-module--headerWrapper--1DjXF";
export var navTitle = "header-module--navTitle--3FtQK";
export var slider = "header-module--slider--2Em0O";
export var open = "header-module--open--2XEis";
export var eduWorkLinks = "header-module--eduWorkLinks--1YG8_";
export var eduWorkLink = "header-module--eduWorkLink--1wGRI";
export var mainHeader = "header-module--mainHeader--3ZboS";
export var navigation = "header-module--navigation---jWYh";
export var subheader = "header-module--subheader--3pFYo";
export var navLink = "header-module--navLink--2QwBR";
export var active = "header-module--active--8yorc";
export var title = "header-module--title--aij_O";
export var menu = "header-module--menu--1LBBy";
export var gradient = "header-module--gradient--1BBJm";
export var childMenu = "header-module--childMenu--1STuN";
export var childMenuTitleContainer = "header-module--childMenuTitleContainer--21zg-";
export var childMenuTitle = "header-module--childMenuTitle--3vWaj";
export var childNav = "header-module--childNav--22DaA";
export var activeChild = "header-module--activeChild--3dVkz";
export var arrow = "header-module--arrow--2Dm6O";
export var arrowReverse = "header-module--arrowReverse--39Jxt";
export var headerIcon = "header-module--headerIcon--18RN3";
export var primaryBlock = "header-module--primaryBlock--2qMC8";
export var gradientBlock = "header-module--gradientBlock--k8BlL";
export var secondaryBlock = "header-module--secondaryBlock--1Z8o_";
export var language = "header-module--language--23uEG";
export var shadow = "header-module--shadow--8oq-D";
export var logotype = "header-module--logotype--2-LOm";
export var buttonContainer = "header-module--buttonContainer--2Bq8A";
export var mobileHeader = "header-module--mobileHeader--3HEbO";
export var search = "header-module--search--2xxDm";
export var headerMenu = "header-module--headerMenu--BIAqN";
export var menuIcon = "header-module--menuIcon--1Kffs";
export var searchHeader = "header-module--searchHeader--10vwO";
export var logo = "header-module--logo--3tWQ2";
export var splitList = "header-module--splitList--lJ_vt";