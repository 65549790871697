import React from "react"
import truncate from "lodash/truncate"
import { Link } from "gatsby"
import * as styles from "./search.module.scss"

function Hit({ hit }) {
  const { title, path, image, body, type } = hit
  const description = truncate(body, { length: 80, separator: " ", omission: " ..." })

  return (
    <Link to={`/${path}`} className={styles.hit}>
      <div className={styles.imageContainer}>{image && <img src={image} alt={title} />}</div>
      <div className={styles.body}>
        <h5>{title}</h5>
        {body && <div className="body3">{description}</div>}
        {type && <div className={styles.type}>{type}</div>}
      </div>
    </Link>
  )
}

export default Hit
