import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classnames from "classnames"
import { LinkTest, LinkFormat } from "../../helpers/linkTest"

import * as styles from "./link.module.scss"

const FormattedLink = props => {
  const { bgColor, theme } = props
  const newTab = props.newTab ? { target: "_blank" } : null
  const noFollow = props.noFollow ? { rel: "nofollow noopener noreferrer" } : null
  const url = props.url || props.href || props.link
  const linkType = LinkTest(url)
  const className = props.className ? props.className : classnames(props.buttonType, props.size.trim(), props.variant)
  const isTel = url?.includes("tel:")
  const isAnchorTarget = url?.includes("industries#")
  const combinedClassName = classnames(className, {
    [styles.specialColorLink]: bgColor === "gradient" && theme === "work",
  })

  if (isTel || isAnchorTarget) {
    return (
      <a className={combinedClassName} href={url}>
        {props.label || props.children}
      </a>
    )
  }

  const Internal = () => (
    <Link
      className={combinedClassName}
      to={LinkFormat(url)}
      {...newTab}
      {...noFollow}
      role={props.role}
      activeClassName={props.activeClassName}>
      {props.label || props.children}
    </Link>
  )

  const External = () => (
    <a href={LinkFormat(url)} className={combinedClassName} {...newTab} {...noFollow} role={props.role}>
      {props.label || props.children}
    </a>
  )

  return linkType === "internal" && !newTab ? <Internal /> : <External />
}

export default FormattedLink

FormattedLink.propTypes = {
  newTab: PropTypes.bool,
  noFollow: PropTypes.bool,
  url: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  activeClassName: PropTypes.string,
  role: PropTypes.string,
  type: PropTypes.oneOf(["internal", "external"]),
  buttonType: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["", "outlined"]),
  bgColor: PropTypes.string,
  theme: PropTypes.string,
}

FormattedLink.defaultProps = {
  variant: "",
  type: "internal",
  size: "",
  newTab: false,
  noFollow: false,
  label: "",
  activeClassName: "",
  role: "",
  className: "",
  buttonType: "",
  bgColor: "",
  theme: "default",
}
