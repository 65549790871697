import React from "react"
import cx from "classnames"
import RichText from "../richText/richText"
import { useConfig } from "../../graphql/useConfig"
import CookieButton from "./cookieButton"

import * as styles from "./cookies.module.scss"

const CookieNotice = () => {
  const { cookieMessage } = useConfig()
  const storageCookies = typeof window !== "undefined" && localStorage.getItem("cookiesAcceptedOLMS")
  const hasCookiesAccepted = storageCookies && JSON.parse(storageCookies)

  if (hasCookiesAccepted) return null

  return (
    <div className={cx(styles.cookieNotice, "container")}>
      <RichText blocks={cookieMessage || []} />
      <CookieButton />
    </div>
  )
}

export default CookieNotice
