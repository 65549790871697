import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FaPlay } from "react-icons/fa"

import * as styles from "./playButton.module.scss"

function PlayButton({ className }) {
  return (
    <div className={classNames(className, styles.playButton)}>
      <FaPlay />
    </div>
  )
}

PlayButton.propTypes = {
  className: PropTypes.string,
}

PlayButton.defaultProps = {
  className: "",
}

export default PlayButton
