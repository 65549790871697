import { useBreakpoint } from "gatsby-plugin-breakpoints"
import React from "react"

import RichText from "../richText/richText"
import MobileTable from "./mobileTable"

import * as styles from "./table.module.scss"

const Table = props => {
  const heading = props.node.heading
  const rows = props.node.rows
  const rowsArr = Object.entries(rows)
  rowsArr.shift() // get rid of "_type"
  const breakpoints = useBreakpoint()
  const isMobile = breakpoints.large

  if (isMobile) return <MobileTable heading={heading} rows={rowsArr} />

  const Row = ({ row }) => (
    <tr>
      {row.flat().map((cell, idx) =>
        idx !== 0 ? (
          // single cell
          <td key={idx} data-before={heading && heading[idx - 1]?.mobileText}>
            <RichText blocks={cell.cell} />
          </td>
        ) : null
      )}
    </tr>
  )

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {heading?.map((cell, idx) => (
            <th key={idx}>
              <RichText blocks={cell.cell} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* TABLE BODY */}
        {rowsArr.map((row, idx) => (
          <Row key={idx} row={row} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
