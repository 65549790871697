import React from "react"

export default Object.freeze({
  all: "all",
  "about the author": "About the author",
  "remove cookies": "remove cookies",
  "accept cookies": "accept cookies",
  search: "search",
  "see how we help higher education": (
    <span>
      See how we help <br /> Higher Education
    </span>
  ),
  "see how we help business": (
    <span>
      See how we help <br /> business
    </span>
  ),
  "see what EDU customers say about us": (
    <span>
      See what EDU customers <br />
      say about us
    </span>
  ),
  "see what WORK customers say about us": (
    <span>
      See what WORK customers <br /> say about us
    </span>
  ),
  "case study": "case study",
  blog: "blog",
  industries: "industries",
  "case studies": "case studies",
  resources: "resources",
  listing: "listing",
  register: "register",
  "read more": "read more",
  "find out more": "find out more",
  "past events": "past events",
  page: "page",
  previous: "previous",
  next: "next",
  tip: "tip",
  "no items found": "no items found",
  categories: "categories",
  by: "by",
  events: "events",
  "link to": "link to",
  oops: "Oops...",
  "page not found message": "This content is no longer available, we suggest you read about:",
  "event passed": "the event has already passed",
  "business we work with": "some of the business industries we work with",
  "education we work with": "Some of the education industries we work with",
  "back to homepage": "Back to homepage",
  "try it free": "Try it free",
  "book a demo": "Book a demo",
})
