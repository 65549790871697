import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import SocialIcon from "../socialIcon/socialIcon"
import Categories from "./categories"

import * as styles from "./footer.module.scss"

const GetInTouch = ({ data }) => {
  const { title, categories, socialMedia } = data

  const SocialPlatforms = () => (
    <div className={styles.social}>
      <p className={cx(styles.title, styles.categoryTitle)}>{socialMedia?.title}</p>
      <ul className={styles.socialList}>
        {socialMedia?.platform.map(link => (
          <li key={link._key}>
            <a href={link.url} target="_blank" rel="noreferrer">
              <SocialIcon type={link.platform} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <div className={styles.getInTouchWrapper}>
      {title && <h2 className={cx(styles.getInTouchTitle, "text-align-center")}>{title}</h2>}
      <div className={styles.getInTouchCategories}>
        <Categories
          data={categories}
          categoryStyles={styles.getInTouchCategory}
          titleStyles={cx(styles.title, styles.categoryTitle)}
        />
        <SocialPlatforms />
      </div>
    </div>
  )
}

GetInTouch.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    categories: PropTypes.array,
    socialMedia: PropTypes.object,
  }),
}

GetInTouch.defaultProps = {
  data: {
    title: "",
    categories: [],
    socialMedia: {},
  },
}

export default GetInTouch
