import { useContext, useEffect, useState } from "react"
import { GraphqlContext } from "./client"

/**
 *
 * @param {*} queryString
 * @returns {{ data: any, loading: boolean, error: any }}
 */
export const useQuery = queryString => {
  const { client } = useContext(GraphqlContext)
  const [data, setData] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    client
      .fetch(queryString)
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(e => {
        setError(e)
        setLoading(false)
      })
  }, [client, queryString])

  return { data, loading, error }
}
