import React from "react"

import FormattedLink from "../formattedLink/formattedLink"

const WrapperComponent = ({ wrapperStyles, children }) =>
  wrapperStyles ? <div className={wrapperStyles}>{children}</div> : <React.Fragment>{children}</React.Fragment>

const Categories = ({ wrapperStyles, categoryStyles, titleStyles, data }) => {
  return (
    <WrapperComponent wrapperStyles={wrapperStyles}>
      {data?.map(category => {
        const { isBlock = false } = category

        if (isBlock) return null
        return (
          <div key={category._key} className={categoryStyles}>
            <p className={titleStyles}>{category.title}</p>
            <ul>
              {category.links.map(link => (
                <li key={link._key}>
                  <FormattedLink url={link.url} label={link.label} newTab={link.newTab} />
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </WrapperComponent>
  )
}

export default Categories
