import classNames from "classnames"
import React, { useCallback } from "react"
import startCase from "lodash/startCase"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./cookies.module.scss"

const COOKIES_ACCEPTED = "cookiesAcceptedOLMS"

const handleAcceptCookies = () => {
  const date = new Date()
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = "expires=" + date.toUTCString()
  document.cookie = "cookiesAcceptedOLMS=true; " + expires + "; path=/"
  document.cookie = "pi_opt_in271292=true; " + expires + "; path=/"
  if (typeof window === "undefined") return
  localStorage.setItem(COOKIES_ACCEPTED, true)
  window.location.reload()
}

const handleRemoveCookies = () => {
  document.cookie = "cookiesAcceptedOLMS=true; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/"
  if (typeof window === "undefined") return
  localStorage.removeItem(COOKIES_ACCEPTED)
  window.location.reload()
}

const CookieButton = ({ className }) => {
  const { t } = useTranslation()
  const storageCookies = typeof window !== "undefined" && localStorage.getItem(COOKIES_ACCEPTED)
  const hasCookiesAccepted = storageCookies && JSON.parse(storageCookies)

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      if (hasCookiesAccepted) handleRemoveCookies()
      else handleAcceptCookies()
    },
    [hasCookiesAccepted]
  )

  const acceptMsg = startCase(t("accept cookies"))
  const removeMsg = startCase(t("remove cookies"))

  return (
    <button className={classNames("btn", styles.cookieButton, className)} onClick={handleClick}>
      <span>{hasCookiesAccepted ? removeMsg : acceptMsg}</span>
    </button>
  )
}

export default CookieButton
