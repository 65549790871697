import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import Image from "../image/image"

import * as styles from "./videoInline.module.scss"

const VideoInline = ({ poster, url, visible, height, loop, isControl }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current && visible) {
      videoRef.current.play()
    }
  }, [visible])

  return (
    <>
      <video
        controls={isControl}
        poster={poster?.url}
        preload="metadata"
        muted
        ref={videoRef}
        style={{ height: `${height}px` }}
        className={isControl ? styles.uploadedVideo : styles.desktopAndTablet}
        loop={loop}>
        <source src={`${url}#t=0.1`} type="video/mp4" />
      </video>
      <div className={styles.mobileOnly}>
        <Image image={poster} alt={poster?.alt} />
      </div>
    </>
  )
}

VideoInline.propTypes = {
  poster: PropTypes.object,
  url: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  height: PropTypes.string,
  loop: PropTypes.bool,
  isControl: PropTypes.bool,
}

VideoInline.defaultProps = {
  poster: {},
  url: "",
  visible: false,
  height: "",
  loop: false,
  isControl: false,
}

export default VideoInline
