import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { useQuery } from "../../graphql/useQuery"
import RichText from "../richText/richText"
import Image from "../image/image"
import Modal from "../modal/modal"
import { getSanityMarketId, markets } from "../../consts/markets"

import * as styles from "./popup.module.scss"

const hasWindow = typeof window !== "undefined"
const EXIT_INTENT_POPUP = "exitIntentPopup"

const getIntentPopupId = location => {
  const [, market] = location.pathname.split("/")
  if (!Object.values(markets).includes(market)) return `${EXIT_INTENT_POPUP}-en-us`
  const marketID = getSanityMarketId(market)
  return `${EXIT_INTENT_POPUP}-${marketID}`
}

const ExitIntentPopup = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const popupId = getIntentPopupId(location)
  const { data } = useQuery(`*[_type == "exitIntentPopup" && _id == "${popupId}"][0]`)

  useEffect(() => {
    const handleMouseLeave = event => {
      if (event.clientY <= 0) {
        if (!localStorage.getItem(EXIT_INTENT_POPUP)) {
          setOpen(true)
          localStorage.setItem(EXIT_INTENT_POPUP, "true")
        }
      }
    }

    if (hasWindow) {
      document.addEventListener("mouseleave", handleMouseLeave)
    }

    return () => {
      if (hasWindow) {
        document.removeEventListener("mouseleave", handleMouseLeave)
      }
    }
  }, [])

  const handleClick = useCallback(() => {
    setOpen(false)
  }, [])

  if (!open || !data?.show) return null

  return (
    <div className={styles.prominentPopup}>
      <Modal
        open={open}
        handleClickForPopup={handleClick}
        canScroll
        isPopup
        modalClassName={styles.popupModal}
        modalContent={
        <div className={styles.content} style={{ color: "white" }}>
            <div className={styles.textContent} style={{ color: "white" }}>{data.body && <RichText blocks={data.body} />}</div>
            <div className={styles.imageContent}>{data.image && <Image image={data.image.asset} />}</div>
          </div>
        }
      />
    </div>
  )
}

export default ExitIntentPopup
