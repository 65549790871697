import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import camelCase from "lodash/camelCase"
import * as styles from "./section.module.scss"
import { BACKGROUNDS } from "../consts"

const Section = ({ children, className, childClassName, background }) => {
  return (
    <section className={classnames(styles.section, styles[camelCase(background)], className)}>
      <div className={childClassName}>{children}</div>
    </section>
  )
}

Section.propTypes = {
  background: PropTypes.oneOf(BACKGROUNDS),
  className: PropTypes.string,
  childClassName: PropTypes.string,
}

Section.defaultProps = {
  background: "none",
  className: "",
  childClassName: "",
}

export default Section
