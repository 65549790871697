import React from "react"

export default Object.freeze({
  all: "todos",
  "about the author": "acerca del autor",
  "remove cookies": "eliminar cookies",
  "accept cookies": "acepto cookies",
  search: "buscar",
  "see how we help higher education": (
    <span>
      Vea cómo ayudamos <br /> a la educación superior
    </span>
  ),
  "see how we help business": (
    <span>
      Vea cómo ayudamos <br /> a las empresas
    </span>
  ),
  "see what EDU customers say about us": (
    <span>
      Vea lo que los clientes <br />
      de EDU dicen sobre nosotros
    </span>
  ),
  "see what WORK customers say about us": (
    <span>
      Vea lo que dicen los clientes <br /> de WORK sobre nosotros
    </span>
  ),
  "case study": "caso de estudio",
  blog: "blog",
  industries: "industrias",
  "case studies": "caso de estudio",
  resources: "recursos",
  listing: "listado",
  register: "registrarse",
  "find out more": "conocer más",
  "past events": "eventos pasados",
  "read more": "conocer más",
  page: "página",
  previous: "previa",
  next: "próxima",
  tip: "propina",
  "no items found": "final",
  categories: "categorias",
  by: "por",
  events: "eventos",
  "link to": "enlace a",
  oops: "Oops...",
  "page not found message": "Este contenido ya no está disponible, te sugerimos leer sobre:",
  "event passed": "el evento ya pasó",
  "business we work with": "algunas de las industrias comerciales con las que trabajamos",
  "education we work with": "algunas de las industrias de la educación con las que trabajamos",
  "back to homepage": "a la página de inicio",
  "try it free": "Pruébalo gratis",
  "book a demo": "Reservar una demo",
})
