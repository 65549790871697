import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"

import OpenLMSLogo from "../../../assets/svgs/logo.svg"
import OpenLMSWorkLogo from "../../../assets/images/work.png"
import OpenLMSEduLogo from "../../../assets/images/edu.png"
import { useHomepageLocation } from "../../helpers/useHomepageLocation"

import * as styles from "./logotype.module.scss"

const Logotype = props => {
  const { theme } = props
  const marketLink = useHomepageLocation()

  const renderLogo = () => {
    switch (theme) {
      case "work":
        return <img src={OpenLMSWorkLogo} alt="OpenLMS WORK" />
      case "edu":
        return <img src={OpenLMSEduLogo} alt="OpenLMS EDU" />
      default:
        return <OpenLMSLogo />
    }
  }

  return (
    <Link to={marketLink} className={classNames(props.className, styles.logotype)}>
      {renderLogo()}
    </Link>
  )
}

Logotype.propTypes = {
  className: PropTypes.string,
}

Logotype.defaultProps = {
  className: "",
}

export default Logotype
