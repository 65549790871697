import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import Laptop from "../../../assets/images/laptop.png"
import Modal from "../modal/modal"
import PlayButton from "../playButton/playButton"
import IframeEmbed from "../iframeEmbed/iframeEmbed"

import * as styles from "./video.module.scss"

function Video(props) {
  const { video, withLaptop, className, btnClassName, width, height, title, previewImage } = props
  const [isLoaded, setLoaded] = useState(false)
  const videoImgRef = useRef(null)

  const watchLoad = useCallback(e => {
    if (e?.target?.complete && e?.target?.naturalHeight !== 0) setLoaded(true)
  }, [])

  useEffect(() => {
    const image = videoImgRef?.current

    if (!isLoaded || !image) {
      image?.addEventListener("load", watchLoad)
    }
    return () => {
      image?.removeEventListener("load", watchLoad)
    }
  }, [watchLoad, isLoaded])

  const Player = playerProps => <IframeEmbed url={video} title={title} {...playerProps} />

  const renderModal = () => {
    if (!video) return null
    return (
      <Modal isAuto modalClassName={styles.player} modalContent={<Player />}>
        <PlayButton className={classNames(styles.playButton, btnClassName)} />
      </Modal>
    )
  }

  if (withLaptop) {
    return (
      <div className={className}>
        <div className={classNames(styles.laptop)} style={{ maxWidth: width }}>
          <img ref={videoImgRef} className={styles.laptopImage} src={Laptop} alt="laptop" />
          {isLoaded && (
            <div className={styles.preview}>{previewImage && <img src={previewImage?.asset?.url} alt="preview" />}</div>
          )}
          {renderModal()}
        </div>
      </div>
    )
  }

  return <Player className={className} custom={{ height, width }} />
}

Video.propTypes = {
  video: PropTypes.string.isRequired,
  withLaptop: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string.isRequired,
}

Video.defaultProps = {
  className: "",
  withLaptop: false,
  width: 650,
}

export default Video
