import React from "react"
import cx from "classnames"

import * as styles from "./blockquote.module.scss"

const Blockquote = ({ quote, author }) => (
  <div className={styles.wrapper}>
    <span className={styles.mark}>“</span>
    <blockquote className={cx("italic", styles.text)}>“{quote}”</blockquote>
    <p className={styles.author}> - {author}</p>
  </div>
)

export default Blockquote
