import React from "react"
import { AiOutlineHome } from "react-icons/ai"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Navigation from "./navigation"
import Subheader from "./subheader"
import MobileHeader from "./mobileHeader"
import LanguageSwitch from "../languageSwitch/languageSwitch"
import SearchInput from "../SearchPage/searchInput"
import { useQuery } from "../../graphql/useQuery"
import { markets, getSanityMarketId } from "../../consts/markets"
import { useHomepageLocation } from "../../helpers/useHomepageLocation"

import * as styles from "./header.module.scss"

const getHeaderId = location => {
  const [, market] = location.pathname.split("/")
  if (!Object.values(markets).includes(market)) return "header-en-us"
  const marketID = getSanityMarketId(market)
  return `header-${marketID}`
}

const Header = props => {
  const { theme } = props
  const location = useLocation()
  const headerId = getHeaderId(location)
  const breakpoints = useBreakpoint()
  const isMobile = breakpoints.xlarge
  const { data } = useQuery(`*[_type == "header" && _id == "${headerId}"][0]`)
  const { headerModule, subheaderModule } = data?.tabs?.content || {}
  const headerTheme = theme === "totara" ? "default" : theme
  const homepageLink = useHomepageLocation()

  if (isMobile) {
    const buttons = subheaderModule?.buttons
    const links = [...(subheaderModule?.links || []), ...(headerModule?.links || [])]
    return <MobileHeader className={styles.headerWrapper} theme={headerTheme} buttons={buttons} links={links} />
  }

  return (
    <header data-theme={headerTheme} className={styles.headerWrapper}>
      <div className={styles.mainHeader}>
        <div className={styles.gradientBlock}>
          <Link to={homepageLink} className={styles.headerIcon} onClick={() => document.activeElement?.blur()}>
            <AiOutlineHome size={30} color="white" />
          </Link>
          <SearchInput />
        </div>
        <div className={styles.secondaryBlock}>
          <Navigation isSubheader links={headerModule?.links} />
          <LanguageSwitch className={styles.language} />
        </div>
      </div>
      {subheaderModule && <Subheader {...subheaderModule} theme={headerTheme} />}
    </header>
  )
}

export default Header
