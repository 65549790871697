// extracted by mini-css-extract-plugin
export var sbShowMain = "eduWorkButton-module--sb-show-main--c7j0Z";
export var button = "eduWorkButton-module--button--1kGI_";
export var edu = "eduWorkButton-module--edu--fx7s9";
export var work = "eduWorkButton-module--work--2UPSF";
export var totara = "eduWorkButton-module--totara--1aZjn";
export var container = "eduWorkButton-module--container--36hQX";
export var rounded = "eduWorkButton-module--rounded--1dj9e";
export var slideBlock = "eduWorkButton-module--slideBlock--2eCaI";
export var descriptionBlock = "eduWorkButton-module--descriptionBlock--32ZVr";
export var description = "eduWorkButton-module--description--3WDyZ";