import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import Image from "../image/image"

import * as styles from "./richCustomColumns.module.scss"

const RichCustomColumns = ({ title, subtitle, items, alignment, layout }) => {
  const alignmentClass = alignment === "left" ? styles.leftAligned : styles.centerAligned
  const layoutClass = layout === "row" ? styles.rowLayout : styles.columnLayout

  return (
    <div className={cx(styles.richCustomColumnsContainer, alignmentClass)}>
      {title && <h2>{title}</h2>}
      {subtitle && <p>{subtitle}</p>}
      <div className={cx(styles.columnsWrapper, layoutClass)}>
        {items.map((item, index) => (
          <div key={String(index)} className={cx(styles.columnItem, layoutClass)}>
            {item.icon?.asset && (
              <div className={styles.imgWrapper}>
                <Image image={item.icon.asset} />
              </div>
            )}
            <div className={styles.textContent}>
              <h2 className={styles.itemTitle}>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

RichCustomColumns.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alignment: PropTypes.oneOf(["left", "center"]),
  layout: PropTypes.oneOf(["сolumns", "row"]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        asset: PropTypes.object.isRequired,
      }),
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RichCustomColumns
