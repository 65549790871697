const email = /\S+@\S+\.\S+/
const external = new RegExp("^(?:[a-z]+:)?//", "i")

export const LinkTest = link => {
  if (typeof link !== "string") return
  if (email.test(link)) {
    return "email"
  }
  if (!external.test(link)) {
    return "internal"
  }

  return "external"
}

export const LinkFormat = link => {
  const linkType = LinkTest(link)

  switch (linkType) {
    case "email":
      const email = link.replace(/^mailto:([^?]+).*/, "$1")
      return `mailto:${email}`

    case "internal":
      // make sure link starts and ends with a /
      let constructedLink = link.charAt(0) !== "/" ? `/${link}` : link
      constructedLink = link.charAt(link.length - 1) === "/" ? constructedLink : `${constructedLink}/`
      return constructedLink

    default:
      return link
  }
}
