import classNames from "classnames"
import React, { useCallback } from "react"

import * as styles from "./menuIcon.module.scss"

const MenuIcon = props => {
  const { className, open, onClick } = props

  const handleClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  return (
    <div className={className} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>
      <div className={classNames(styles.menuIcon, open && styles.open)}>
        <span />
        <span />
        <span />
      </div>
    </div>
  )
}

export default MenuIcon
