// extracted by mini-css-extract-plugin
export var sbShowMain = "search-module--sb-show-main--3imEJ";
export var input = "search-module--input--2GwGR";
export var search = "search-module--search--sIJ_H";
export var circleArrow = "search-module--circleArrow--17I4p";
export var emptyState = "search-module--emptyState--2CA1N";
export var hit = "search-module--hit--3X_hr";
export var body = "search-module--body--3RJbZ";
export var type = "search-module--type--3bRQg";
export var imageContainer = "search-module--imageContainer--1M5ja";
export var mobileSearch = "search-module--mobileSearch--1E0gr";
export var closeButton = "search-module--closeButton--PI94y";