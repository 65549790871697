import React, { useLayoutEffect, useRef, useState, useCallback } from "react"
import { Link } from "gatsby"
import { RiArrowDownSLine } from "react-icons/ri"
import Slide from "react-reveal/Slide"
import classNames from "classnames"

import EduWorkButton from "../ctaEduWork/eduWorkButton"
import SplitList from "../splitList/splitList"
import { useConfig } from "../../graphql/useConfig"
import FormattedLink from "../formattedLink/formattedLink"

import * as styles from "./header.module.scss"

const SubheaderDropdown = props => {
  const { list, isOpen, isMobile } = props
  const listType = list?.[0]?._type
  const { eduLink, workLink, totaraLink } = useConfig()
  const [target, setTarget] = useState(null)
  const menuRef = useRef()

  const handleClick = useCallback(e => {
    e.stopPropagation()
    setTarget(prev => (e?.target.innerText === prev ? null : e.target.innerText))
  }, [])

  const renderCtaEduWork = list => {
    const { edu, work, totara } = list[0]
    return (
      <div>
        <EduWorkButton className={styles.eduWorkLink} variant="rounded" type="edu" url={eduLink} description={edu} />
        <EduWorkButton className={styles.eduWorkLink} variant="rounded" type="work" url={workLink} description={work} />
      </div>
    )
  }

  const renderChildMenu = list => {
    if (!list?.length) return null
    return (
      <>
        {(list || []).map((props, idx) => (
          <FormattedLink className="h6" key={props._key || idx} {...props}>
            {props.title}
          </FormattedLink>
        ))}
      </>
    )
  }

  const renderMenu = () => {
    return (
      <div>
        {(list || []).map((props, idx) => {
          return (
            <div key={idx}>
              {!props.childList ? (
                <FormattedLink className="h6" key={props._key || idx} {...props}>
                  {props.title}
                </FormattedLink>
              ) : (
                <div className={styles.childMenu} key={props._key || idx}>
                  <div
                    className={styles.childMenuTitleContainer}
                    onClick={handleClick}
                    onKeyPress={handleClick}
                    role="button"
                    tabIndex={0}>
                    <span className={styles.childMenuTitle}>{props.title}</span>
                    <RiArrowDownSLine
                      className={classNames(styles.arrow, props.title === target && styles.arrowReverse)}
                    />
                  </div>
                  <div className={classNames(styles.childNav, props.title === target && styles.activeChild)}>
                    {renderChildMenu(props.childList)}
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  useLayoutEffect(() => {
    if (!menuRef.current) return
    const rect = menuRef.current.getBoundingClientRect() || {}
    const isLeft = menuRef.current.style.right === "0px"
    const isOutOfScreen = rect.right > window.screen.width

    if (isOutOfScreen && !isLeft) {
      menuRef.current.style.right = 0
      menuRef.current.style.left = "initial"
    }
    if (!isOutOfScreen && isLeft) {
      menuRef.current.style.right = "initial"
      menuRef.current.style.left = 0
    }
  }, [])

  const renderDropdown = () => {
    let Component = null
    switch (listType) {
      case "ctaEduWorkLinks":
        Component = () => renderCtaEduWork(list)
        break
      case "navLinkListItem":
        Component = renderMenu
        break
      case "splitList":
        Component = () => <SplitList {...list[0]} />
        break
      default:
        Component = () =>
          list.map(i => (
            <Link className="h6" to={i.url}>
              {i.title}
            </Link>
          ))
    }

    const body = (
      <div
        className={classNames(
          styles.menu,
          listType === "splitList" && styles.gradient,
          listType === "ctaEduWorkLinks" && styles.eduWorkLinks,
          listType === "splitList" && styles.splitList
        )}
        style={{ zIndex: -1 }}>
        <Component />
      </div>
    )

    if (isMobile) return <div className={classNames(styles.slider, isOpen && styles.open)}>{body}</div>
    return (
      <Slide innerRef={ref => (menuRef.current = ref)} collapse top when={isOpen} duration={800}>
        {body}
      </Slide>
    )
  }

  return renderDropdown()
}

export default SubheaderDropdown
