import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { InstantSearch, SearchBox, Configure } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { useLocation } from "@reach/router"
import upperFirst from "lodash/upperFirst"

import CircleArrow from "../../../assets/svgs/circle-arrow.svg"
import CustomHits from "./customHits"
import MobileSearchInput from "./mobileSearchInput"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./search.module.scss"

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_PROJECT_ID, process.env.GATSBY_ALGOLIA_READ_API_KEY)

const getMarketId = path => {
  if (path.includes("/es")) return "es"
  if (path.includes("/au")) return "au"
  if (path.includes("/pt")) return "pt"
  return "en-us"
}

const SearchInput = props => {
  const { className, isMobile, onClose } = props
  const searchInputRef = useRef()
  const containerRef = useRef()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const market = getMarketId(pathname)
  const indexName = process.env.GATSBY_DATASET

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const watchClick = useCallback(e => {
    if (containerRef.current?.contains(e.target)) return
    setFocused(false)
  }, [])

  useEffect(() => {
    if (!focused) return
    document.addEventListener("click", watchClick)
    return () => {
      document.addEventListener("click", watchClick)
    }
  }, [focused, watchClick])

  useEffect(() => {
    const input = searchInputRef.current
    if (!input) return
    input.placeholder = upperFirst(t("search"));
  }, [t])

  if (isMobile)
    return <MobileSearchInput market={market} indexName={indexName} className={className} client={searchClient} onClose={onClose} />

  return (
    <div>
      <div ref={containerRef} className={classNames(styles.input, className)} onFocus={handleFocus} role="searchbox">
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          <Configure filters={`market:${market} OR market:any`} />
          <div className={styles.search}>
            <SearchBox inputRef={searchInputRef} />
            {!focused && <CircleArrow className={styles.circleArrow} />}
          </div>
          {focused && <CustomHits />}
        </InstantSearch>
      </div>
    </div>
  )
}

export default SearchInput
