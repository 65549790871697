import React, { useCallback, useEffect, useRef, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { useLocation } from "@reach/router"
import classNames from "classnames"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { markets, getSanityMarketId } from "../../consts/markets"
import { useQuery } from "../../graphql/useQuery"
import Image from "../image/image"
import RichText from "../richText/richText"
import Modal from "../modal/modal"

import * as styles from "./popup.module.scss"

const hasWindow = typeof window !== "undefined"
const POPUP = "popup"

const getPopupId = location => {
  const [, market] = location.pathname.split("/")
  if (!Object.values(markets).includes(market)) return "popup-en-us"
  const marketID = getSanityMarketId(market)
  return `popup-${marketID}`
}

const Popup = () => {
  const popupRef = useRef(null)
  const breakpoints = useBreakpoint()
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const popupId = getPopupId(location)
  const { data } = useQuery(`*[_type == "popup" && _id == "${popupId}"][0]`)
  const isTop = data?.position === "top"
  const isBottom = data?.position === "bottom"
  const hasData = !!data
  const isMobile = breakpoints.xlarge

  useEffect(() => {
    const isPopupViewed = hasWindow && localStorage.getItem(POPUP)
    if (!isPopupViewed && hasData) setTimeout(() => setOpen(true), 3000)
  }, [hasData])

  useEffect(() => {
    if (!open || !popupRef.current) return

    setTimeout(() => {
      if (isTop) popupRef.current.style.top = isMobile ? "75px" : "173px"
      else popupRef.current.style.bottom = "0"
    }, 400)
  }, [open, isTop, isMobile])

  const handleClick = useCallback(() => {
    if (isTop) {
      popupRef.current.style.top = "-20px"
    } else {
      popupRef.current.style.bottom = "-80px"
    }

    if (hasWindow) localStorage.setItem(POPUP, "true")

    setTimeout(() => {
      setOpen(false)
    }, 200)
  }, [isTop])

  if (!open || !data || !data.show) return null

  const renderPopupContent = (
    <>
      <IoMdClose className={styles.close} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0} />
      <div className={styles.content}>
        <div className={styles.textContent}>{data.body && <RichText blocks={data.body} />}</div>
        <div className={styles.imageContent}>{data.image && <Image image={data.image.asset} />}</div>
      </div>
    </>
  )

  const renderTopBottomPopup = (
    <div
      ref={popupRef}
      className={classNames(styles.popup, isTop && styles.top)}
      {...(isTop ? { id: "top-popup" } : {})}>
      {renderPopupContent}
    </div>
  )

  const renderProminentPopup = (
    <div ref={popupRef} className={classNames(styles.prominentPopup)}>
      <Modal
        open={open}
        handleClickForPopup={handleClick}
        canScroll
        isPopup
        modalClassName={styles.popupModal}
        modalContent={renderPopupContent}
      />
    </div>
  )

  return isTop || isBottom ? renderTopBottomPopup : renderProminentPopup
}

export default Popup
