import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import FormattedLink from "../formattedLink/formattedLink"

import * as styles from "./buttons.module.scss"

const Buttons = ({ buttons, className, xl, variant }) => {
  if (!buttons?.length) return null
  return (
    <div className={classNames(styles.buttonContainer, className)}>
      {buttons.map((props, idx) => (
        <FormattedLink
          variant={variant}
          key={props._key || idx}
          size={xl ? "btn-xl" : ""}
          {...props}
          buttonType={props.buttonType}
        />
      ))}
    </div>
  )
}

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  xl: PropTypes.bool,
  variant: PropTypes.oneOf(["", "outlined"]),
}

Buttons.defaultProps = {
  buttons: [],
  className: "",
  xl: false,
  variant: "",
}

export default Buttons
