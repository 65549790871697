// extracted by mini-css-extract-plugin
export var sbShowMain = "popup-module--sb-show-main--10Y9F";
export var prominentPopup = "popup-module--prominentPopup--22z6y";
export var close = "popup-module--close--GNgY_";
export var popupModal = "popup-module--popupModal--3KjrX";
export var popup = "popup-module--popup--1IOKo";
export var open = "popup-module--open--16lA-";
export var top = "popup-module--top--3F0Pl";
export var content = "popup-module--content--2l6L1";
export var textContent = "popup-module--textContent--2PGaQ";
export var imageContent = "popup-module--imageContent--gO5B2";