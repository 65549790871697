import React from "react"

import Facebook from "../../../assets/svgs/social-facebook.svg"
import Instagram from "../../../assets/svgs/social-instagram.svg"
import LinkedIn from "../../../assets/svgs/social-linkedin.svg"
import Twitter from "../../../assets/svgs/social-twitter.svg"
import YouTube from "../../../assets/svgs/social-youtube.svg"

const SocialIcon = props => {
  switch (props.type) {
    case "Facebook":
      return <Facebook />

    case "Instagram":
      return <Instagram />

    case "LinkedIn":
      return <LinkedIn />

    case "Twitter":
      return <Twitter />

    case "YouTube":
      return <YouTube />

    default:
      return null
  }
}

export default SocialIcon
