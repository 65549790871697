import React, { useRef, useEffect, useCallback } from "react"
import { InstantSearch, SearchBox, Configure } from "react-instantsearch-dom"
import classNames from "classnames"
import PropTypes from "prop-types"
import upperFirst from "lodash/upperFirst"

import CustomHits from "./customHits"
import MenuIcon from "../menuIcon/menuIcon"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./search.module.scss"

const MobileSearchInput = props => {
  const { client, className, onClose, indexName, market } = props
  const searchInputRef = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    const input = searchInputRef.current
    if (!input) return
    input.placeholder = upperFirst(t("search"))
    setTimeout(() => {
      input.style.width = "100%"
    }, 100)
  }, [t])

  const handleClose = useCallback(() => {
    searchInputRef.current.style.width = "75px"
    setTimeout(() => {
      onClose()
    }, 300)
  }, [onClose])

  return (
    <div className={classNames(styles.mobileSearch, className)}>
      <InstantSearch indexName={indexName} searchClient={client}>
        <Configure filters={`market:${market} OR market:any`} />
        <SearchBox inputRef={searchInputRef} />
        <CustomHits />
        <div className={styles.closeButton} tabIndex={0} onClick={handleClose} onKeyPress={handleClose} role="button">
          <MenuIcon open />
        </div>
      </InstantSearch>
    </div>
  )
}

MobileSearchInput.propTypes = {
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  indexName: PropTypes.string.isRequired,
}

MobileSearchInput.defaultProps = {
  className: "",
}

export default MobileSearchInput
