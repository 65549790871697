import { useCallback } from "react"
import { useLocation } from "@reach/router"

import { useQuery } from "./useQuery"

/**
 *
 * @returns {object}
 * @description requests current market site config
 */
export const useConfig = () => {
  const { pathname } = useLocation()

  const getMarketId = useCallback(() => {
    const [, market = "us"] = pathname.split("/")

    if (market === "es") return "es"
    if (market === "au") return "au"
    if (market === "pt") return "pt"
    return "us"
  }, [pathname])

  const marketId = getMarketId()
  const query = `*[_type == "config" && _id == "config_${marketId}"][0]`

  const { data } = useQuery(query)

  return data || {}
}
