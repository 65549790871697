// extracted by mini-css-extract-plugin
export var sbShowMain = "footer-module--sb-show-main--2rbLU";
export var footer = "footer-module--footer--eMWRA";
export var title = "footer-module--title--3Zgg5";
export var categoryPart = "footer-module--categoryPart--az4ws";
export var categoryWrapper = "footer-module--categoryWrapper--1vwe0";
export var category = "footer-module--category--3W4LY";
export var categoryTitle = "footer-module--categoryTitle--ITlNA";
export var getInTouchPart = "footer-module--getInTouchPart--gm9XL";
export var legalPart = "footer-module--legalPart--20dIo";
export var getInTouchWrapper = "footer-module--getInTouchWrapper--13ZGo";
export var getInTouchTitle = "footer-module--getInTouchTitle--1naLi";
export var getInTouchCategories = "footer-module--getInTouchCategories--GKjm4";
export var getInTouchCategory = "footer-module--getInTouchCategory--2J80R";
export var social = "footer-module--social--23sMz";
export var socialList = "footer-module--socialList---BlBB";
export var legalWrapper = "footer-module--legalWrapper--2n827";
export var ltgLogo = "footer-module--ltgLogo--1GtnY";