// extracted by mini-css-extract-plugin
export var sbShowMain = "richCustomColumns-module--sb-show-main--1fxGe";
export var richCustomColumnsContainer = "richCustomColumns-module--richCustomColumnsContainer--3GnHr";
export var columnsWrapper = "richCustomColumns-module--columnsWrapper--2xCcF";
export var columnLayout = "richCustomColumns-module--columnLayout--1RGfQ";
export var rowLayout = "richCustomColumns-module--rowLayout--2b-vb";
export var columnItem = "richCustomColumns-module--columnItem--1xkYc";
export var textContent = "richCustomColumns-module--textContent--CqkfZ";
export var imgWrapper = "richCustomColumns-module--imgWrapper--3jWZ3";
export var itemTitle = "richCustomColumns-module--itemTitle--il3sg";
export var leftAligned = "richCustomColumns-module--leftAligned--31OLo";
export var centerAligned = "richCustomColumns-module--centerAligned--1Owrp";