import React from "react"

export default Object.freeze({
  all: "todos",
  "about the author": "sobre o autor",
  "remove cookies": "remover cookies",
  "accept cookies": "aceitar cookies",
  search: "pesquisa",
  "see how we help higher education": (
    <span>
      Veja como ajudamos <br /> o ensino superior
    </span>
  ),
  "see how we help business": (
    <span>
      Veja como ajudamos <br /> as empresas
    </span>
  ),
  "see what EDU customers say about us": (
    <span>
      Veja o que os clientes <br />
      EDU dizem sobre nós
    </span>
  ),
  "see what WORK customers say about us": (
    <span>
      Veja o que os clientes <br /> da WORK têm a dizer sobre nós
    </span>
  ),
  "case study": "estudo de caso",
  blog: "blogue",
  industries: "sectores",
  "case studies": "estudos de caso",
  resources: "recursos",
  listing: "lista",
  register: "registo",
  "find out more": "saber mais",
  "past events": "eventos passados",
  "read more": "ler mais",
  page: "página",
  previous: "prévia",
  next: "próxima",
  tip: "ponta",
  "no items found": "nenhum item encontrado",
  categories: "categorias",
  by: "por",
  events: "evento",
  "link to": "ligação a",
  oops: "Oops...",
  "page not found message": "Este conteúdo já não está disponível, sugerimos que leia sobre:",
  "event passed": "o evento terminou",
  "business we work with": "alguns dos sectores comerciais com que trabalhamos",
  "education we work with": "alguns dos sectores da educação com que trabalhamos",
  "back to homepage": "para a página inicial",
  "try it free": "Experimente gratuitamente",
  "book a demo": "Marcar uma demonstração",
})
