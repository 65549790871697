import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Carousel from "react-multi-carousel"

import CircleArrow from "../../../assets/svgs/circle-arrow.svg"

import * as styles from "./partnersCarousel.module.scss"

import "react-multi-carousel/lib/styles.css"

const Plug = () => <div />

const richTextResponsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 0 },
    items: 2,
  },
}

const PartnersCarousel = props => {
  const { partners, title, isAuto, className, isRichText } = props

  const partnersCount = partners?.length || 0

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2001 },
      items: Math.min(partnersCount, 8),
    },
    largeDesktop: {
      breakpoint: { max: 2000, min: 1401 },
      items: Math.min(partnersCount, 6),
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: Math.min(partnersCount, 4),
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: Math.min(partnersCount, 2),
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  const renderItem = (item, idx) => {
    const Container = ({ children, ...props }) =>
      item.url ? (
        <a href={item.url} target="_blank" rel="noreferrer" {...props}>
          {children}
        </a>
      ) : (
        <div {...props}>{children}</div>
      )

    return (
      <Container key={item?.logo?.asset?.url || idx} className={styles.item}>
        <img src={item.logo.asset.url} alt={item.title} />
      </Container>
    )
  }

  const ButtonGroup = ({ next, previous, carouselState }) => {
    const { slidesToShow } = carouselState
    const isLess = partners?.length <= slidesToShow
    if (isLess) return null

    return (
      <div className={styles.buttonGroup}>
        <CircleArrow className={styles.leftArrow} onClick={() => previous()} />
        <CircleArrow className={styles.rightArrow} onClick={() => next()} />
      </div>
    )
  }

  const settings = {
    className: styles.carousel,
    infinite: true,
    draggable: false,
    ssr: true,
    autoPlay: isAuto,
    renderButtonGroupOutside: true,
    customLeftArrow: <Plug />,
    customRightArrow: <Plug />,
    customButtonGroup: <ButtonGroup />,
    responsive: isRichText ? richTextResponsive : responsive,
  }

  return (
    <div className={classNames(styles.partnersCarousel, className)}>
      {title && <h3 className="text-align-center mb-m">{title}</h3>}
      <Carousel {...settings}>{partners.map(renderItem)}</Carousel>
    </div>
  )
}

PartnersCarousel.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  title: PropTypes.string,
  isAuto: PropTypes.bool,
  isRichText: PropTypes.bool,
}

PartnersCarousel.defaultProps = {
  className: "",
  partners: [],
  title: "",
  isAuto: false,
  isRichText: false,
}

export default PartnersCarousel
