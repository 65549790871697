import React from "react"
import { Hits, connectStateResults } from "react-instantsearch-dom"
import upperCase from "lodash/upperCase"
import Hit from "./hit"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./search.module.scss"

const CustomHits = ({ allSearchResults }) => {
  const hasQuery = !!allSearchResults?.query?.length
  const { t } = useTranslation();

  if (!hasQuery) return null

  const hasHits = !!allSearchResults?.hits?.length
  if (!hasHits) return <div className={styles.emptyState}>{upperCase(t("no items found"))}</div>

  return <Hits hitComponent={Hit} />
}

export default connectStateResults(CustomHits)
