import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import PropTypes from "prop-types"

import { config } from "../../helpers/clientConfig"

const Image = ({ image, alt, width, height, className, fit }) => {
  if (!image) return null

  const size = {
    ...(width ? { maxWidth: width } : {}),
    ...(height ? { maxHeight: height } : {}),
  }
  const sanityImg = getGatsbyImageData(image._id || image._ref, size, config)
  const isGif = image.extension === "gif"
  const isSVG = image.mimeType?.includes("svg")
  const objectFit = fit ? fit : "contain"

  return (
    <figure className={className}>
      {isGif || isSVG ? (
        <img src={image.url} alt={alt} />
      ) : (
        <GatsbyImage
          image={sanityImg}
          alt={alt}
          width={width}
          height={height}
          imgStyle={{
            objectFit,
          }}
        />
      )}
    </figure>
  )
}

Image.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  fit: PropTypes.string,
}

Image.defaultProps = {
  alt: "",
  width: null,
  height: null,
  className: "",
  fit: "contain",
}

export default React.memo(Image)
