import React from "react"
import PropTypes from "prop-types"
import upperCase from "lodash/upperCase";
import RichText from "./richText"
import { useTranslation } from "../../helpers/useTranslation";

import * as styles from "./tip.module.scss"

const Tip = ({ tipText }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <span className={styles.note}>{upperCase(t("tip"))}</span>
      <RichText blocks={tipText} />
    </div>
  )
}

Tip.propTypes = {
  tipText: PropTypes.array.isRequired,
}

Tip.defaultProps = {
  tipText: [],
}

export default Tip
