import React, { useCallback, useEffect, useRef, useState } from "react"
import { RiArrowDownSLine } from "react-icons/ri"
import classNames from "classnames"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import Slide from "react-reveal/Slide"
import cx from "classnames"

import SubheaderDropdown from "./subheaderDropdown"

import * as styles from "./header.module.scss"

const NavLink = props => {
  const { title, list, url, newTab, isSubheader, isMobile } = props
  const navRef = useRef()
  const menuRef = useRef()
  const [open, setOpen] = useState(false)
  const isDropdown = !!list?.length

  const clickListener = useCallback(
    e => {
      if (!navRef.current?.contains(e.target) && open) {
        setOpen(false)
      }
    },
    [open]
  )

  const tabListener = useCallback(
    e => {
      const isTab = e.which === 9
      if (isTab && open && !navRef.current?.contains(e.target)) {
        setOpen(false)
      }
    },
    [open]
  )

  useEffect(() => {
    if (!open) {
      const navTitle = navRef.current?.firstChild
      const isEqual = document.activeElement.isEqualNode(navTitle)
      if (isEqual) document.activeElement?.blur()
    }
  }, [open])

  useEffect(() => {
    document.addEventListener("click", clickListener)
    document.addEventListener("keyup", tabListener)
    return () => {
      document.removeEventListener("click", clickListener)
      document.removeEventListener("keyup", tabListener)
    }
  }, [clickListener, tabListener])

  useEffect(() => {
    if (!menuRef.current) return
    const rect = menuRef.current.getBoundingClientRect() || {}
    const isLeft = menuRef.current.style.right === "0px"
    const isOutOfScreen = rect.right > window.screen.width

    if (isOutOfScreen && !isLeft) {
      menuRef.current.style.right = 0
      menuRef.current.style.left = "initial"
    }
    if (!isOutOfScreen && isLeft) {
      menuRef.current.style.right = "initial"
      menuRef.current.style.left = 0
    }
  }, [])

  const handleClick = useCallback(() => {
    isDropdown && setOpen(provided => !provided)
    if (!url) return
    if (newTab) window.open(url, "new_window")
    else navigate(url)
  }, [isDropdown, url, newTab])

  const renderSimpleMenu = () => {
    if (!list?.length) return null
    return (
      <Slide innerRef={ref => (menuRef.current = ref)} collapse top when={open} duration={400}>
        <div className={styles.menu} style={{ zIndex: -1 }}>
          {list.map(({ title, url }) => (
            <Link to={url} className="h6">
              {title}
            </Link>
          ))}
        </div>
      </Slide>
    )
  }

  const renderSubheaderMenu = () => {
    if (!list?.length) return null
    return <SubheaderDropdown isMobile={isMobile} isOpen={open} list={list} />
  }

  const menu = isSubheader || isMobile ? renderSubheaderMenu() : renderSimpleMenu()

  return (
    <div ref={navRef} className={classNames(styles.navLink, open && styles.active)}>
      <div className={styles.navTitle} onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
        <span className={cx(styles.title, "h6")}>{title}</span>
        {isDropdown && <RiArrowDownSLine className={classNames(styles.arrow, open && styles.arrowReverse)} />}
      </div>
      {menu}
    </div>
  )
}

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.any),
  isSubheader: PropTypes.bool,
}

NavLink.defaultProps = {
  list: [],
  isSubheader: false,
}

export default NavLink
