import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import FormattedLink from "../formattedLink/formattedLink"
import Image from "../image/image"

import * as styles from "./splitList.module.scss"

const SplitList = props => {
  const { articles, className, image } = props
  const renderArticle = (article, idx) => {
    const { additionalLinks, title, text, button } = article
    const isFirst = !idx
    const cutText = isFirst ? text.slice(0, 255) : text.slice(0, 100)

    const body = cutText.length === text.length ? cutText : `${cutText}...`
    return (
      <div key={article._key} className={classNames(styles.article)}>
        <div className={styles.body}>
          <div className={styles.title}>{title}</div>
          {body}
        </div>
        {!idx && <Image className={styles.img} image={image.asset} />}
        <div className={styles.buttonsWrapper}>
          <FormattedLink {...button} variant="outlined" tabIndex={0} />
          {additionalLinks?.map((item, index) => {
            return <FormattedLink {...item} variant="outlined" tabIndex={index + 1} key={String(index)} />
          })}
        </div>
      </div>
    )
  }

  return <div className={classNames(styles.splitList, className)}>{articles.map(renderArticle)}</div>
}

SplitList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
}

SplitList.defaultProps = {
  articles: [],
  className: "",
}

export default SplitList
