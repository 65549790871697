import React, { useCallback, useRef, useState } from "react"
import { AiOutlineHome } from "react-icons/ai"
import { Link } from "gatsby"
import Slide from "react-reveal/Slide"
import classNames from "classnames"
import { GoSearch } from "react-icons/go"

import Navigation from "./navigation"
import FormattedLink from "../formattedLink/formattedLink"
import LanguageSwitch from "../languageSwitch/languageSwitch"
import MenuIcon from "../menuIcon/menuIcon"
import SearchInput from "../SearchPage/searchInput"
import Logotype from "../logotype/logotype"
import { useHomepageLocation } from "../../helpers/useHomepageLocation"

import * as styles from "./header.module.scss"

const MobileHeader = props => {
  const { links, buttons, theme, className } = props
  const [open, setOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const homepageLink = useHomepageLocation()

  const handleHomeClick = useCallback(() => {
    if (open) setOpen(false)
  }, [open])

  const handleOpen = useCallback(() => {
    setOpen(provided => !provided)
  }, [setOpen])

  const handleSearchClick = useCallback(() => {
    setSearchOpen(true)
  }, [])

  const handleSearchClose = useCallback(() => {
    setSearchOpen(false)
  }, [])

  const renderButtons = () => {
    if (!buttons?.length) return null
    return (
      <div className={styles.buttonContainer}>
        {buttons.map((props, idx) => (
          <FormattedLink key={props._key || idx} buttonType={idx ? "btn btn-bordered" : props.buttonType} {...props} />
        ))}
      </div>
    )
  }

  const renderMenuIcon = () =>
    searchOpen ? null : (
      <div
        className={classNames(styles.headerIcon, styles.menuIcon)}
        onClick={handleOpen}
        role="button"
        onKeyPress={handleOpen}
        tabIndex={0}>
        <MenuIcon open={open} />
      </div>
    )

  const renderHeader = () => {
    if (open && searchOpen)
      return (
        <div className={styles.searchHeader}>
          <SearchInput isMobile onClose={handleSearchClose} />
        </div>
      )

    if (open)
      return (
        <div className={styles.mainHeader}>
          <div className={styles.primaryBlock}>
            <Link to={homepageLink} onClick={handleHomeClick} className={classNames(styles.headerIcon)}>
              <AiOutlineHome size={35} color="white" />
            </Link>
            <LanguageSwitch className={styles.language} />
            <div
              className={classNames(styles.headerIcon, styles.search)}
              onKeyPress={handleSearchClick}
              onClick={handleSearchClick}
              tabIndex={0}
              role="button">
              <GoSearch />
            </div>
          </div>
          <div className={styles.gradientBlock} />
        </div>
      )
    return (
      <div className={styles.mainHeader}>
        <Logotype className={styles.logo} theme={theme} />
      </div>
    )
  }

  const renderHeaderMenu = () => (
    <Slide when={open} collapse top>
      <div>
        <div className={styles.headerMenu}>
          <Navigation isMobile links={links} />
          {renderButtons()}
        </div>
      </div>
    </Slide>
  )

  return (
    <header data-theme={theme} className={className}>
      <div className={classNames(styles.mobileHeader, open && styles.open)}>
        {renderMenuIcon()}
        {renderHeader()}
        {renderHeaderMenu()}
      </div>
    </header>
  )
}

export default MobileHeader
