import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./iframeEmbed.module.scss"

const IframeEmbed = props => {
  const { ratio, url, className, custom, title, ...rest } = props

  const setLength = i => {
    let customAspect = null
    if (custom?.width && custom?.height) {
      customAspect = `${custom.width}:${custom.height}`
    }
    const aspectRatio = customAspect || ratio || "16:9"

    return aspectRatio.split(":")[i]
  }

  const aspectWidth = setLength(0)
  const aspectHeight = setLength(1)
  const aspectRatio = `${(aspectHeight / aspectWidth) * 100}%`

  return (
    <div className={styles.iframeWrapper} style={{ paddingTop: aspectRatio }}>
      <iframe
        title={title}
        allowFullScreen
        frameBorder="0"
        loading="lazy"
        src={url}
        className={classNames(className, styles.iframe)}
        {...rest}
      />
    </div>
  )
}

IframeEmbed.propTypes = {
  title: PropTypes.string,
  ratio: PropTypes.string,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  custom: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
}

IframeEmbed.defaultProps = {
  custom: null,
  ratio: "16:9",
  className: "",
  title: "",
}

export default IframeEmbed
