import React from "react"
import cx from "classnames"

import Image from "../image/image"
import FormattedLink from "../formattedLink/formattedLink"

import * as styles from "./inlineImage.module.scss"

const InlineImage = ({ image, alignment, link }) => {
  return (
    <div className={cx(styles.wrapper, styles[alignment])}>
      {link?.link ? (
        <FormattedLink url={link.link} newTab={link.newTab} noFollow={link.noFollow}>
          <Image image={image?.asset} alt={image.alt} />
        </FormattedLink>
      ) : (
        <Image image={image?.asset} alt={image.alt} />
      )}
    </div>
  )
}

export default InlineImage
